<template>
  <div>
    <div class="content">
      <div class="localicon"></div>
      <div class="cont">
        <div class="title">联系我们</div>
        <div class="des">Contact us</div>
        <div class="local">
          <div class="localitem">
            <div class="icon"><img class="iconimg" src="@/assets/call/localfill.png" alt="" /></div>
            <span>地址：北京市海淀区高梁桥斜街42号院1号楼2层2-215</span>
          </div>
          <div class="localitem">
            <div class="icon"><img src="@/assets/call/hand.png" alt="" /></div>
            <span>商务合作邮箱：ruyunxinda@163.com</span>
          </div>
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Foot from '@/components/Foot'

export default {
  name: 'Call',
  components: {
    Foot
  },
  methods: {},
  computed: {}
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  background: url(../../assets/call/bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 974px;
  position: relative;
  .localicon {
    position: absolute;
    background: url(../../assets/call/local.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 38px;
    height: 54px;
    bottom: 254px;
    left: calc(50% - 19px);
  }
  .cont {
    margin: auto;
    width: 75%;
  }
  .title {
    font-size: 48px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 64px;
    padding-top: 100px;
    font-weight: 550;
  }
  .des {
    font-size: 20px;
    font-family: MicrosoftSansSerif;
    color: #999999;
    line-height: 22px;
  }
  .local {
    display: flex;
    align-items: center;
    margin-top: 48px;
    // line-height: 46px;
    // height: 46px;
    .localitem {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin: auto 0;
      margin-right: 64px;
      span {
        margin-left: 17px;
        display: inline-block;
        line-height: 46px;
      }
      .icon {
        display: inline-block;
        line-height: 46px;

        img {
          margin-bottom: -12px;
          display: inline-block;
          width: 48px;
          height: 44px;
        }
        .iconimg {
          width: 47px;
          height: 46px;
        }
      }
    }
  }
}
</style>
